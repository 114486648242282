import axios from "axios";

const prepaidOrderApi = axios.create({
	baseURL: "/api/prepaid-order",
	withCredentials: true,
	timeout: 20000,
});

const prepaidTopUp = (msisdn, netsPaymentRef) =>
	prepaidOrderApi.post("/add-prepaid-funds/", {
		msisdn,
		netsPaymentRef,
	});

const numberIsPrepaid = (msisdn) => prepaidOrderApi.get(`/numberIsPrepaid/${msisdn}/?time=${new Date().getTime()}`);

export default {
	prepaidTopUp,
	numberIsPrepaid,
};
