<template>
	<SelfServiceWizardStep>
		<template #headline>
			<h2>Lad kontantkort</h2>
		</template>
		<template #main>
			<UiMessage
				type="warn"
				v-if="reservationFailure"
				title="Vi klarte ikke gjennomføre betalingen"
				:xs="true"
				message="Vennligst prøv igjen, og kontroller at du har tastet inn korrekt betalingsdetaljer. Kontakt kundeservice dersom problemet vedvarer."
			/>
			<UiMessage
				type="success"
				v-else-if="reservationSuccess"
				title="Betalingen var vellykket"
				:xs="true"
				:message="successMessage"
			/>
		</template>
		<template #actions>
			<TnButton
				@click="$emit('update:modelValue')"
				class="margin-top-xl"
				>Lukk</TnButton
			>
		</template>
	</SelfServiceWizardStep>
</template>

<script>
import queryParameter from "~/helpers/queryParameter";

export default defineNuxtComponent({
	name: "PrepaidPaymentProcessing",

	data() {
		return {
			prepaidStatus: useRoute().query.prepaidStatus,
			paymentRef: useRoute().query.ref,
			error: null,
		};
	},

	computed: {
		reservationSuccess() {
			return this.prepaidStatus === "payment_succeeded";
		},
		reservationFailure() {
			return this.prepaidStatus === "payment_failed";
		},
		successMessage() {
			return `Kontantkortet er ladet. Det kan ta noen minutter før saldoen oppdateres. Referanse ${this.paymentRef}`;
		},
	},

	methods: {},

	mounted() {
		queryParameter.removeQueryParameter("ref", this);
		queryParameter.removeQueryParameter("prepaidStatus", this);
	},
});
</script>
